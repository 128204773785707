import getRoute from 'utils/getRoute'

const routes = {
  base: '/admin',
  applicants: {
    base: '/applicants',
    add: '/add',
    administered: 'administered',
  },
  applicant: {
    base: '/applicant',
    detail: '/:id',
  },
  draw: {
    base: '/draw',
    detail: '/:drawId',
    hcv: '/hcv',
    pbv: '/pbv',
  },
  properties: {
    base: '/properties',
  },
  property: {
    base: '/property',
    detail: '/:id',
  },
  tenants: {
    base: '/tenants',
    create: '/create/:memberId',
  },
  tenant: {
    base: '/tenant',
    detail: '/:id',
  },
  logout: '/logout',
}

export default getRoute(routes)
