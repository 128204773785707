import { get, isEmpty, flow, split, initial, join, map } from 'lodash/fp'

export const sharedRoutes = {
  accountExists: '/accountexists',
  signin: '/signin',
  signup: {
    base: '/signup',
    ssn: '/ssn',
    credentials: '/credentials',
    noMatch: '/no-match',
  },
  profile: {
    base: '/profile',
    edit: '/edit',
  },
  editName: {
    base: '/editName',
    review: '/review',
    submit: '/submit',
  },
  editLogin: {
    base: '/editLogin',
    review: '/review',
    submit: '/submit',
  },
  contactPreferences: {
    base: '/contactPreferences',
  },
  bugReport: {
    base: '/bugReport',
    review: '/review',
  },
  changeLanguage: {
    base: '/changeLanguage',
  },
  password: {
    base: '/password',
    reset: '/reset',
    update: {
      base: '/update',
      token: '?t=:t',
    },
  },
}

const getRoute = routes => (path, variables = {}, current = '') => {
  if (isEmpty(path)) return (routes.base || '') + current

  const key = get(path)(routes)
  const route = typeof key === 'string' ? key : key?.base
  const interpolated = flow(
    split(/(?=:|&)/),
    map(el => get(el.substring(1), variables) || el),
    join('')
  )(route)

  const remaining = flow(
    split('.'),
    initial,
    join('.')
  )(path)

  return getRoute(routes)(remaining, variables, interpolated + current)
}

export default getRoute
