import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { min } from 'lodash/fp'

import CircularProgress from '@material-ui/core/CircularProgress'

const Loading = ({ classes, fullScreen, size = 80, width, height, ...props }) => (
  <div
    style={{ width, height }}
    className={classNames(classes.loadingContainer, { [classes.fullScreen]: fullScreen })}
  >
    <CircularProgress
      aria-busy="true"
      color="primary"
      variant="indeterminate"
      disableShrink
      size={min([width && width * 0.75, height && height * 0.75, size])}
      thickness={4}
      {...props}
    />
  </div>
)

Loading.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  size: PropTypes.number,
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool,
}

const styles = theme => ({
  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  fullScreen: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: 10000,
  },
})

export default withStyles(styles)(Loading)
