import { isIE, isIEOrEdgeOrFirefox } from 'utils/browser'
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  errorMessage: {
    color: '#EB5757',
    fontSize: '0.85rem',
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: `0px !important`,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
        color: '#737373',
      },
      outlinedPrimary: {
        borderColor: '#B3B3B3',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
      },
    },
  },
  palette: {
    common: {
      grey: '#D2D2D2',
      lightGrey: '#737373',
      darkGrey: '#929292',
      purple: '#512D6D',
      darkPurple: '#3D1E57',
    },
    primary: {
      contrastText: '#FFFFFF',
      dark: '#3D1E57',
      main: '#512D6D',
    },
    secondary: {
      contrastText: '#FFFFFF',
      main: '#0091D1',
      veryLight: '#E6F1F6',
    },
    error: {
      dark: '#E9033A',
      light: '#EB5757',
      main: '#EB5757',
    },
    disabled: {
      main: '#929292',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F3F3F3',
    },
    text: {
      light: '#F2EEEF5',
      main: '#202020',
      disabled: '#B3B3B3',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    h1: {
      fontWeight: 600,
      fontSize: '2.25rem',
      lineHeight: '2.875rem',
      letterSpacing: 0.5,
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.375rem',
      lineHeight: '1.75rem',
      letterSpacing: 0.5,
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.25rem',
    },
    overline: {
      fontSize: '0.65rem',
      fontWeight: 500,
      letterSpacing: 1.5,
    },
  },
  margins: {
    xxl: '60px',
    xl: '40px',
    lg: '32px',
    xm: '26px',
    md: '24px',
    sm: '20px',
    xs: '16px',
    xxs: '8px',
    toInt: margin => Number(margin.slice(0, -2)),
  },
  isIE: isIE(),
  isIEOrEdgeOrFirefox: isIEOrEdgeOrFirefox(),
  borderRadius: {
    lg: 10,
  },
  dimensions: {
    appBarHeight: 48,
  },
  formClasses: {
    flex: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    buttonPadding: {
      paddingBottom: 64,
    },
  },
  marginClasses: {
    xxsTopMargin: {
      marginTop: 8,
    },
    xsTopMargin: {
      marginTop: 16,
    },
    smTopMargin: {
      marginTop: 20,
    },
    mdTopMargin: {
      marginTop: 24,
    },
    xmTopMargin: {
      marginTop: 26,
    },
    lgTopMargin: {
      marginTop: 32,
    },
    xlTopMargin: {
      marginTop: 40,
    },
    xxlTopMargin: {
      marginTop: 60,
    },
    xxsBtmMargin: {
      marginBottom: 8,
    },
    xsBtmMargin: {
      marginBottom: 16,
    },
    smBtmMargin: {
      marginBottom: 20,
    },
    mdBtmMargin: {
      marginBottom: 24,
    },
    lgBtmMargin: {
      marginBottom: 32,
    },
    xlBtmMargin: {
      marginBottom: 40,
    },
    xxlBtmMargin: {
      marginBottom: 60,
    },
  },
})

export default theme
