import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import theme from 'styles/theme'

const AppLayout = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline>
      <Grid container justify="center" id="appContainer">
        {children}
      </Grid>
    </CssBaseline>
  </MuiThemeProvider>
)

AppLayout.propTypes = {
  children: PropTypes.node,
}

export default AppLayout
