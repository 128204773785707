import React from 'react'
import ReactDOM from 'react-dom'
import Logger from 'js-logger'

import App from './App'
import { unregister } from './registerServiceWorker'
import { isProduction } from './config'

import 'typeface-roboto'
import './index.css'
import './utils/errorReporting'

if (isProduction()) {
  Logger.setLevel(Logger.OFF)
} else {
  Logger.useDefaults()
}

ReactDOM.render(<App />, document.getElementById('root'))
unregister()
