import React from 'react'
import PropTypes from 'prop-types'

import { ENVIRONMENT } from 'src/config'

const ErrorFallback = ({ error }) => (
  <div>
    <h2>Oops! Something went wrong.</h2>
    {ENVIRONMENT !== 'production' && (
      <>
        {error.message && (
          <div>
            <h3>Message:</h3>
            <div>{error.message}</div>
          </div>
        )}
        {error.stack && (
          <div>
            <h3>Stack Trace:</h3>
            <div>{error.stack}</div>
          </div>
        )}
      </>
    )}
  </div>
)

ErrorFallback.propTypes = {
  error: PropTypes.object,
}

export default ErrorFallback
