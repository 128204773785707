import React, { Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import AppLayout from 'components/AppLayout'
import HotJar from 'components/HotJar'
import Loading from 'components/Loading'
import getAdminRoute from 'Admin/utils/getAdminRoute'
import { ErrorBoundary } from 'utils/errorReporting'
import { retryLazy } from 'utils/retry'

// Importing slick.js CSS here to fix lazy loading bug
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'app.css'
import 'mobiscroll.scss'

const ClientApp = retryLazy(() => import('components/ClientApp'))
const AdminApp = retryLazy(() => import('Admin/components/AdminApp'))

const App = () => (
  <ErrorBoundary>
    <HotJar />
    <AppLayout>
      <BrowserRouter>
        <Suspense fallback={<Loading fullScreen />}>
          <Switch>
            <Route path={getAdminRoute()}>
              <AdminApp />
            </Route>
            <Route path="/">
              <ClientApp />
            </Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </AppLayout>
  </ErrorBoundary>
)

export default App
