import { AuthenticationContext, runWithAdal as RARunWithAdal, withAdalLogin } from 'react-adal'

import { adminRolesDisabled } from 'config'

export const adalConfig = {
  tenant: process.env.REACT_APP_AD_TENANT_ID,
  clientId: process.env.REACT_APP_AD_CLIENT_ID,
  endpoints: {
    api: process.env.REACT_APP_AD_CLIENT_ID,
  },
  cacheLocation: 'sessionStorage',
  redirectUri: `${window.location.origin}/admin`,
  postLogoutRedirectUri: `${window.location.origin}/admin`,
  localLoginUrl: `${window.location.origin}/admin`,
}

export const authContext = new AuthenticationContext(adalConfig)

export const runWithAdal = (init, doNotLogin) => RARunWithAdal(authContext, init, doNotLogin)

export const roles = () =>
  authContext && authContext._user ? authContext._user.profile.roles || [] : []

const hasRole = givenRole => roles().includes(givenRole)

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api)

export const getAdalToken = () => authContext.getCachedToken(authContext.config.clientId)

export const getAdalUsername = () =>
  authContext && authContext._user && authContext._user.profile.name

export const permissions = () => {
  if (adminRolesDisabled()) {
    return {
      roles: ['Manage_Users', 'PBV_Draw', 'HCV_Draw', 'Upload_Property_List', 'Download_Files'],
      canManageUsers: true,
      canPBVDraw: true,
      canHVCDraw: true,
      canUploadPropertyList: true,
      canDownloadFiles: true,
    }
  }

  return {
    roles: roles(),
    canManageUsers: hasRole('Manage_Users'),
    canPBVDraw: hasRole('PBV_Draw'),
    canHVCDraw: hasRole('HCV_Draw'),
    canUploadPropertyList: hasRole('Upload_Property_List'),
    canDownloadFiles: hasRole('Download_Files'),
  }
}
