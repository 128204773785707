import { lazy } from 'react'

const retry = (fn, retriesLeft = 5, interval = 1000) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        // If the code updates mid-session, we are sometimes seeing errors like
        // the following: "Error: Loading chunk 4 failed."
        //
        // It is unclear how to best address this but some recommend simply
        // catching this specific error and reloading in response:
        //
        // https://github.com/facebook/create-react-app/issues/4152#issuecomment-415950842
        // https://github.com/facebook/create-react-app/issues/3613#issuecomment-431516064
        //
        // All of these Loading chunk errors appear to be caught here based on
        // the error stacktraces so catch/reload here.
        if (error.message.includes('Loading chunk')) {
          logger.info('Reloading after chunk error')
          window.location.reload(true)
          return
        }

        setTimeout(() => {
          if (retriesLeft === 1) {
            return reject(error)
          }
          logger.warn(`[Retry error: (retries left: ${retriesLeft})]: ${error}`)
          return retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })

export const retryLazy = (...args) => lazy(() => retry(...args))

export default retry
