import { detect } from 'detect-browser'

const browser = detect() || {}

export const isIE = () => browser.name === 'ie'

export const isEdge = () => browser.name === 'edge'

export const isFirefox = () => browser.name === 'firefox'

export const isChrome = () => browser.name === 'chrome'

export const majorBrowserVersion = () =>
  parseInt(browser.version.substring(0, 2).padStart(2, '0'), 10)

export const isIEOrEdgeOrFirefox = () => isIE() || isEdge() || isFirefox()

export default browser
