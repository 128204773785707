import React from 'react'
import PropTypes from 'prop-types'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import ErrorFallback from 'components/ErrorFallback'
import {
  ENVIRONMENT,
  REACT_APP_BUGSNAG_KEY,
  REACT_APP_ENABLE_ERROR_REPORTING,
  REACT_APP_BUGSNAG_ENVIRONMENT,
} from 'src/config'

const bugsnagEnv = REACT_APP_BUGSNAG_ENVIRONMENT || ENVIRONMENT
const bugsnagClient = bugsnag({
  logger,
  apiKey: REACT_APP_BUGSNAG_KEY,
  releaseStage: bugsnagEnv,
  notifyReleaseStages: [REACT_APP_ENABLE_ERROR_REPORTING === 'true' ? bugsnagEnv : 'none'],
})

bugsnagClient.use(bugsnagReact, React)

export default bugsnagClient

export const identifyUser = user => {
  bugsnagClient.user = user
}

export const reportError = error => {
  if (!bugsnagClient) return logger.warn('Bugsnag error reporter called before it was initialized!')
  return bugsnagClient.notify(error)
}

const BugsnagErrorBoundary = bugsnagClient.getPlugin('react')

export const ErrorBoundary = ({ FallbackComponent = ErrorFallback, children }) => (
  <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>{children}</BugsnagErrorBoundary>
)

ErrorBoundary.propTypes = {
  FallbackComponent: PropTypes.node,
  children: PropTypes.node,
}
