import smoothscroll from 'smoothscroll-polyfill'
import { runWithAdal } from './adalConfig'

smoothscroll.polyfill()

const DO_NOT_LOGIN = true

runWithAdal(() => {
  // eslint-disable-next-line
  require('./AppIndex.js')
}, DO_NOT_LOGIN)
