import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()

export const {
  REACT_APP_AMPLITUDE_API_KEY,
  REACT_APP_BUGSNAG_ENVIRONMENT,
  REACT_APP_BUGSNAG_KEY,
  REACT_APP_ENABLE_ERROR_REPORTING,
  REACT_APP_GRAPHQL_API_URL,
  REACT_APP_HOTJAR_ENABLED,
  REACT_APP_LOCIZE_API_KEY,
  REACT_APP_LOCIZE_PROJECT_ID,
  REACT_APP_LOCIZE_VERSION,
  REACT_APP_DISABLE_ADMIN_ROLES,
} = env

export const { NODE_ENV } = process.env

/*
  NOTE: ENVIRONMENT should be used in place of NODE_ENV for
  determining what environment the app is running in. As a
  consequence of using the create-react-app-buildpack on
  Heroku, NODE_ENV === 'development' even in production/staging
  environments.
*/
export const ENVIRONMENT = env.REACT_APP_ENVIRONMENT || NODE_ENV || 'development'

export const isDevelopment = () => ENVIRONMENT === 'development'
export const isStaging = () => ENVIRONMENT === 'staging'
export const isProduction = () => ENVIRONMENT === 'production'
export const isTest = () => ENVIRONMENT === 'test'
export const isHotJarEnabled = () => isProduction() && REACT_APP_HOTJAR_ENABLED === 'true'
export const adminRolesDisabled = () => REACT_APP_DISABLE_ADMIN_ROLES === 'true'

export const ENABLE_FEEDBACK = env.REACT_APP_ENABLE_FEEDBACK === 'true'

export const ACCEPTED_FILE_TYPES = [
  '.doc',
  '.docx',
  '.jpg',
  '.jpeg',
  '.pdf',
  '.png',
  '.rtf',
  '.txt',
]

export const MAX_FILE_UPLOAD_MBS = 25
export const MAX_FILE_UPLOAD_BYTES = MAX_FILE_UPLOAD_MBS * 1048576

export const DEFAULT_RESPONSE_TIME = 5

export const ACTION_I18N_MAP = {
  appointmentScheduled: 'appointmentScheduled',
  addHousehold: 'addToHousehold',
  addToHousehold: 'additionToHousehold',
  addMinor: 'addMinorToHousehold',
  income: 'incomeChange',
  message: 'message',
  pending: 'pendingInfoRequest',
  accommodation: 'accommodationRequest',
  accommodationRequest: 'accommodation',
  vacate: 'vacate',
  deleteHousehold: 'deleteFromHousehold',
  rentPortionChange: 'rentPortionChange',
}

export const SETTINGS_FORMS = [
  'editName',
  'editLogin',
  'changeLanguage',
  'contactPreferences',
  'bugReport',
]

export const ACTION_NAMESPACES = [
  'additionToHousehold',
  'addToHousehold',
  'accommodation',
  'accommodationRequest',
  'deleteFromHousehold',
  'incomeChange',
  'vacate',
  'message',
  ...SETTINGS_FORMS,
]

export const PBV_UNIT_SIZES = ['SRO', '0', '1', '2', '3', '4']

export const PBV_AGE_REQUIREMENTS = ['family', 'restricted', 'senior']

export const HCV = 'hcv'
export const PBV = 'pbv'
export const DRAW_TYPES = [HCV, PBV]

export const pbvAccessibilities = [
  'accessibleWheelchair',
  'accessibleVisionHearing',
  'accessibleAdapted',
]

export const extraRoomAccommodations = [
  'extraRoomAide',
  'extraRoomEquipment',
  'extraRoomDisability',
]

export const ScchaDirectionsURL =
  'https://www.google.com/maps/search/?api=1&query=505+West+Julian+Street+San+José+CA+95110'

export const IL = 'IL'
export const TAP = 'TAP'

export const LOGOUT_PATH = '/logout'

export const SCCHA_WEBSITE_URL = 'http://scchousingauthority.org'
